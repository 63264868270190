import { Fragment } from "react";

import { CategoryEnum } from "~/src/types/models/article";
import { VideoArticle } from "~/src/types/pages/home.types";
import { returnMediaType } from "~/src/utils/constants/mediaTypes";

import HomeArticle from "../../category/Article";
import HorizontalLine from "../../ui/HorizontalLine";
import styles from "./index.module.scss";

type Props = {
  articles: VideoArticle[];
};

const RecommendedVideos = ({ articles }: Props) => {
  return (
    <div className={styles.recommendedVideoArticles}>
      <h2 className={styles.title}>Notre Sélection</h2>
      <h4>Découvrez nos catégories</h4>
      <div className={styles.recommendedVideosContainer}>
        <HomeArticle
          notClickableView
          title="Documentaire Donbass/bande annonce"
          media={{
            type: "video",
            path: "https://d3nok0m49vtvbs.cloudfront.net/video_dessous1.mp4",
          }}
          main={true}
          desktopVideo={true}
          category={CategoryEnum.DEFAULT}
        />
        <HomeArticle
          notClickableView
          title="Découvrez notre magazine"
          media={{
            type: "video",
            path: "https://d3nok0m49vtvbs.cloudfront.net/video_dessus2.mp4",
          }}
          main={true}
          desktopVideo={true}
          category={CategoryEnum.DEFAULT}
        />
        {articles.map((article, i) => (
          <Fragment key={article.id}>
            <HomeArticle
              {...article}
              media={{
                ...article.media,
                type: returnMediaType(article.media?.type),
              }}
              main={true}
              desktopVideo={true}
            />
            {i < articles.length - 1 && <HorizontalLine />}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default RecommendedVideos;
