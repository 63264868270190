import { useState } from "react";
import Collapsible from "react-collapsible";
import { AiOutlineClose } from "react-icons/ai";
import { RiArrowUpSLine } from "react-icons/ri";
import { RiArrowDownSLine } from "react-icons/ri";

import { useAuth } from "~/src/contexts/providers/auth";
import { useBreakpoint } from "~/src/contexts/providers/breakpoint";
import { SUBSCRIPTION_PAGE } from "~/src/utils/constants/page";

import MainButton from "../../common/MainButton";
import styles from "./index.module.scss";

const Banner = () => {
  const [invisible, setInvisible] = useState(false);
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint == "XL" || breakpoint == "XXL";
  const { user } = useAuth();
  return invisible ? null : (
    <Collapsible
      trigger={
        <div className={styles.banner}>
          <RiArrowUpSLine />
          <div className={styles.bannerText}>
            <div className={styles.close}>
              <h2>Soutenez un média 100% indépendant</h2>
              {!isDesktop && (
                <AiOutlineClose
                  className={styles.closeIcon}
                  onClick={() => {
                    setInvisible(true);
                  }}
                />
              )}
            </div>
            <p>
              Et informez-vous en toute confiance grâce à une rédaction libre de
              toutes pressions
            </p>
          </div>

          {!user?.subscription?.isActive && (
            <MainButton
              theme="primary"
              className={`${styles.bannerBtn} ${styles.closed}`}
              href={SUBSCRIPTION_PAGE}
            >
              S’abonner
            </MainButton>
          )}
        </div>
      }
      triggerWhenOpen={
        <div className={`${styles.banner} ${styles.opened}`}>
          <RiArrowDownSLine />
          <div className={styles.bannerText}>
            <h2>
              Participez à l'essor d'un média d'investigation 100% indépendant
            </h2>
            <p>
              OMERTA a fait le choix de l'indépendance. Cette liberté n'est
              possible que grâce à vous. S'abonner à OMERTA, c'est participer au
              renouvellement de l'information. C'est donner à nos journalistes
              la liberté d'enquêter sans tabous et sans œillères sur des sujets
              jusque-là ignorés par les médias traditionnels. Enfin, c'est
              contribuer à l'essor d'un média d'investigation exigeant et proche
              du terrain.
            </p>
          </div>
          {!user?.subscription?.isActive && (
            <MainButton
              theme="primary"
              className={styles.bannerBtn}
              href={SUBSCRIPTION_PAGE}
            >
              S’abonner
            </MainButton>
          )}
        </div>
      }
    />
  );
};

export default Banner;
