import { GetStaticProps } from "next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { OpenInAppBanner } from "react-open-in-app-banner";

import HomeArticle from "~/src/components/home/Article";
import FeaturedSectionA from "~/src/components/home/FeaturedSectionA";
import HomeSubscribeWidget from "~/src/components/home/HomeSubscribeWidget";
import HomeMainArticle from "~/src/components/home/MainArticle";
import MiniArticlesSection from "~/src/components/home/MiniArticlesSection";
import RecommendedVideos from "~/src/components/home/RecommendedVideos";
import Banner from "~/src/components/ui/Banner";
import HorizontalLine from "~/src/components/ui/HorizontalLine";
import { useBreakpoint } from "~/src/contexts/providers/breakpoint";
import styles from "~/src/styles/index.module.scss";

import {
  getHomePageArticles,
  HomePageArticles,
} from "../api/rest/articles/getHomeArticles";
import ArticlesSection from "../components/home/ArticleSection";
import BottomFeaturedSection from "../components/home/BottomFeaturedSection";
import StudentSubscribeWidget from "../components/home/StudentSubscribeWidget";
import SEO from "../components/SEO";
import { CategoryEnum } from "../types/models/article";

export const getStaticProps: GetStaticProps = async () => {
  const { data } = await getHomePageArticles();
  return { props: data, revalidate: 60 };
};

const HomePage = ({
  desktopArticles,
  mainTopArticles,
  featureArticleA,
  featureVideosA,
  featureArticleB,
  featureVideosB,
  featureArticleC,
  featureArticleD,
  economyArticles,
  geopoliticsArticles,
  politicsArticles,
  societyArticles,
  investigationsArticles,
  cultureArticles,
  suggestedArticles,
}: HomePageArticles) => {
  const breakpoint = useBreakpoint();

  const isDesktop = breakpoint === "XL" || breakpoint === "XXL";

  const image = {
    url: "https://d3nok0m49vtvbs.cloudfront.net/we.jpg",
    alt: "OMERTA-logo",
    width: 750,
    height: 500,
  };

  return (
    <>
      <SEO
        title={"OMERTA"}
        description={
          "Découvrez OMERTA, le média d'enquête et de documentaires 100% digital."
        }
        noindex={false}
        nofollow={false}
        image={image}
      />
      {typeof window !== "undefined" && (
        <OpenInAppBanner
          appName={"Omerta"}
          bannerDescription={"Ouvrir dans l'app Omerta"}
          playStoreAppId={"4972603767881013762"}
          appStoreAppId={"6443905422"}
          appStoreAppName={"OMERTA"}
          style={{
            zIndex: 999,
          }}
          openButtonLabel={"Ouvrir"}
          buttonColor={"var(--primary-color)"}
          appIcon={
            <LazyLoadImage
              style={{ width: "2.5rem", height: "2.5rem" }}
              src={"/logo.svg"}
              alt="omerta-logo"
            />
          }
        />
      )}
      <div className={styles.container}>
        {isDesktop && !!desktopArticles && (
          <MiniArticlesSection data={desktopArticles} />
        )}
        <div className={`${styles.articlesContainer} container`}>
          <div className={styles.mainArticlesContainer}>
            {!!mainTopArticles && <HomeMainArticle {...mainTopArticles} />}
            <HorizontalLine />
            <FeaturedSectionA
              article={featureArticleA}
              videos={featureVideosA}
            />
            {!isDesktop && (
              <LazyLoadImage
                className={styles.img}
                src="/images/omerta-offer.png"
                alt="reduction"
              />
            )}
            <HorizontalLine />
            <HomeArticle {...featureArticleB} main={false} singleArticle />
            {isDesktop && <HorizontalLine />}
            {(!!featureArticleC.length || !!featureArticleD.length) && (
              <BottomFeaturedSection
                featuredC={featureArticleC[0]}
                featuredD={featureArticleD[0]}
                videosB={featureVideosB}
              />
            )}
            <HorizontalLine />

            <div className={styles.banners}>
              <HomeSubscribeWidget />
              <StudentSubscribeWidget />
            </div>

            <ArticlesSection
              category={CategoryEnum.INVESTIGATIONS}
              mainCategory={investigationsArticles.main[0]}
              text1={investigationsArticles.articles[0]}
              text2={investigationsArticles.articles[1]}
              videos={investigationsArticles.video}
              text3={investigationsArticles.articles[2]}
            />
            <ArticlesSection
              category={CategoryEnum.GEOPOLITICS}
              mainCategory={geopoliticsArticles.main[0]}
              text1={geopoliticsArticles.articles[0]}
              text2={geopoliticsArticles.articles[1]}
              videos={geopoliticsArticles.video}
              text3={geopoliticsArticles.articles[2]}
            />
            <ArticlesSection
              category={CategoryEnum.SOCIETY}
              mainCategory={societyArticles.main[0]}
              text1={societyArticles.articles[0]}
              text2={societyArticles.articles[1]}
              videos={societyArticles.video}
              text3={societyArticles.articles[2]}
            />
            <ArticlesSection
              category={CategoryEnum.ECONOMY}
              mainCategory={economyArticles.main[0]}
              text1={economyArticles.articles[0]}
              text2={economyArticles.articles[1]}
              videos={economyArticles.video}
              text3={economyArticles.articles[2]}
            />
            <ArticlesSection
              category={CategoryEnum.POLITICS}
              mainCategory={politicsArticles.main[0]}
              text1={politicsArticles.articles[0]}
              text2={politicsArticles.articles[1]}
              videos={politicsArticles.video}
              text3={politicsArticles.articles[2]}
            />
            <ArticlesSection
              category={CategoryEnum.CULTURE}
              mainCategory={cultureArticles.main[0]}
              text1={cultureArticles.articles[0]}
              text2={cultureArticles.articles[1]}
              videos={cultureArticles.video}
              text3={cultureArticles.articles[2]}
            />
          </div>
          {isDesktop && <RecommendedVideos articles={suggestedArticles} />}
        </div>
      </div>
      <Banner />
    </>
  );
};

export default HomePage;
