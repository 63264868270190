import { useBreakpoint } from "~/src/contexts/providers/breakpoint";
import { Article, CategoryEnum } from "~/src/types/models/article";
import {
  MainCategoryArticle,
  VideoArticle,
} from "~/src/types/pages/home.types";
import { categoryNames } from "~/src/utils/constants/categories";

import HomeArticle from "../../category/Article";
import styles from "./index.module.scss";

type Props = {
  category: CategoryEnum;
  mainCategory: MainCategoryArticle;
  text1: Article;
  text2: Article;
  videos: VideoArticle[];
  text3: Article;
};

const ArticlesSection = ({
  category,
  mainCategory,
  text1,
  text2,
  videos,
  text3,
}: Props) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint == "XL" || breakpoint == "XXL";

  const Hr = <div className={styles.horizontalLine} />;
  const Vr = <div className={styles.verticalLine} />;

  return (
    <section
      className={`${styles.articleSection} ${
        category == CategoryEnum.INVESTIGATIONS ? styles.investigations : ""
      }`}
    >
      <h1 className={styles.sectionTitle}>{categoryNames[category]}</h1>
      <div
        className={` ${
          category == CategoryEnum.INVESTIGATIONS && isDesktop
            ? styles.firstArticle
            : ""
        }`}
      >
        <HomeArticle
          hideCategory
          {...mainCategory}
          main={isDesktop}
          firstMainArticle={category === CategoryEnum.INVESTIGATIONS}
        />
      </div>
      {Hr}
      <div className={styles.textArticleContainer}>
        <HomeArticle {...text1} hideCategory />
        {isDesktop ? Vr : Hr}
        <HomeArticle {...text2} hideCategory />
      </div>
      {Hr}
      <div className={styles.textArticleContainer}>
        <div className={styles.textArticleContainer}>
          {videos[0] && (
            <HomeArticle
              {...videos[0]}
              media={{ ...videos[0].media, type: "video" }}
              main={isDesktop}
              desktopVideo={true}
              videos
            />
          )}
          {!isDesktop && Hr}
          {videos[1] && (
            <HomeArticle
              {...videos[1]}
              media={{ ...videos[1].media, type: "video" }}
              main={isDesktop}
              desktopVideo={true}
              videos
            />
          )}
        </div>
        {isDesktop ? Vr : Hr}
        <HomeArticle {...text3} hideCategory />
      </div>
    </section>
  );
};

export default ArticlesSection;
