import { SUBSCRIPTION_PAGE } from "~/src/utils/constants/page";

import MainButton from "../../common/MainButton";
import SubscribeWidgetIcon from "../../icons/subscribe-widget.icon";
import styles from "./index.module.scss";

const HomeSubscribeWidget = () => {
  return (
    <div className={styles.homeSubscribeWidget}>
      <SubscribeWidgetIcon />
      <div className={styles.textContainer}>
        <h1>OMERTA ne vit que du soutien de ses abonnés</h1>
        <p>
          Pour nous soutenir et profiter de nos contenus, souscrivez à nos
          offres d'abonnement à partir de 4,99 € par mois ou 49,99 € par an.
        </p>
        <MainButton href={SUBSCRIPTION_PAGE}>S’abonner</MainButton>
      </div>
    </div>
  );
};

export default HomeSubscribeWidget;
