import { LazyLoadImage } from "react-lazy-load-image-component";

import { Media } from "~/src/types/models/article";

import styles from "./index.module.scss";

type Props = Media & {
  allArticles?: boolean;
  singleArticle?: boolean;
};

const HomeArticleMedia = ({
  path,
  type,
  caption,
  credit,
  allArticles = false,
  singleArticle = false,
}: Props) => {
  // if (type != "image" && type != "video")
  //   throw new Error("Media type must be either image or video");
  return (
    <figure className={styles.homeArticleMedia}>
      {type.toLowerCase() === "image" ? (
        <LazyLoadImage
          src={path}
          className={
            allArticles
              ? `${styles.allMedia}`
              : singleArticle
              ? styles.singleMedia
              : styles.media
          }
          alt={caption ?? credit ?? path}
        />
      ) : (
        <video
          className={allArticles ? `${styles.allMedia}` : styles.media}
          controls={true}
        >
          <source src={path} type="video/mp4" />
          {/* <ReactPlayer src={src} className={styles.media} /> */}
        </video>
      )}
      {!!caption && (
        <figcaption className={styles.figureCaption}>
          {caption}{" "}
          {!!credit && (
            <p className={styles.figureSubCaption}>
              © {type.toLowerCase() === "video" ? "Video" : "Photo"} {credit}
            </p>
          )}
        </figcaption>
      )}
    </figure>
  );
};

export default HomeArticleMedia;
