import { SUBSCRIPTION_PAGE } from "~/src/utils/constants/page";

import MainButton from "../../common/MainButton";
import StudentWidgetIcon from "../../icons/student-widget.icon";
import styles from "./index.module.scss";

const StudentSubscribeWidget = () => {
  return (
    <div className={styles.studentSubscribeWidget}>
      <StudentWidgetIcon />
      <div className={styles.textContainer}>
        <h1>
          Vous êtes étudiant<span>(e)</span> ?
        </h1>
        <p>Abonnez-vous à un tarif préférentiel</p>
        <MainButton theme="white" href={SUBSCRIPTION_PAGE}>
          S’abonner
        </MainButton>
      </div>
    </div>
  );
};

export default StudentSubscribeWidget;
