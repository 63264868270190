import { useBreakpoint } from "~/src/contexts/providers/breakpoint";
import { Article } from "~/src/types/models/article";
import { VideoArticle } from "~/src/types/pages/home.types";

import HomeArticle from "../../category/Article";
import HorizontalLine from "../../ui/HorizontalLine";
import VerticalLine from "../../ui/VerticalLine";
import styles from "./index.module.scss";

type Props = {
  featuredC: Article;
  featuredD: Article;
  videosB: VideoArticle[];
};

const BottomFeaturedSection = ({ featuredC, featuredD, videosB }: Props) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === "XL" || breakpoint === "XXL";

  return (
    <div className={styles.bottomSection}>
      <div className={styles.leftSection}>
        {/* <div className={styles.videos}>
          <FeaturedVideos articles={videosB} />
        </div>
        <HorizontalLine /> */}
        <HomeArticle {...featuredC} />
      </div>
      {isDesktop && <VerticalLine />}
      {!isDesktop && <HorizontalLine />}
      <div className={styles.rightSection}>
        <HomeArticle {...featuredD} />
      </div>
    </div>
  );
};

export default BottomFeaturedSection;
