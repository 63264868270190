import Link from "next/link";

import { useBreakpoint } from "~/src/contexts/providers/breakpoint";
import { Media, OptionalArticle } from "~/src/types/models/article";
import { categoryNames } from "~/src/utils/constants/categories";
import { ARTICLE_PAGE } from "~/src/utils/constants/page";

import styles from "./index.module.scss";
import HomeArticleMedia from "./Media";

type Props = OptionalArticle & {
  media?: Media;
  main?: boolean;
  always?: boolean;
  desktopVideo?: boolean;
  hasDate?: boolean;
  other?: boolean;
  allArticles?: boolean;
  singleArticle?: boolean;
  categoryPage?: boolean;
};

const HomeArticle = ({
  slug,
  title,
  category,
  author,
  header,
  media,
  main = false,
  desktopVideo = false,
  always = false,
  hasDate = false,
  other = false,
  publishedAt,
  allArticles = false,
  singleArticle = false,
  categoryPage = false,
}: Props) => {
  const Media =
    media != undefined ? (
      <HomeArticleMedia
        {...media}
        allArticles={allArticles}
        singleArticle={singleArticle}
      />
    ) : null;

  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === "XL" || breakpoint === "XXL";

  return (
    <Link href={`${ARTICLE_PAGE}/${slug}`}>
      <article
        className={`${styles.homeArticle} ${
          main && !desktopVideo ? styles.main : ""
        }`}
      >
        <div
          className={`
          ${
            singleArticle
              ? styles.singleDetails
              : categoryPage
              ? styles.categoryPage
              : styles.details
          }
          `}
        >
          {(isDesktop || always) && Media}
          <div className={styles.articleInfo}>
            <div>
              {!other && (
                <h3 className={styles.category}>{categoryNames[category]}</h3>
              )}
              {main && !isDesktop && !desktopVideo && <div>{Media}</div>}
              {desktopVideo ? (
                <h3 className={styles.title}>{title}</h3>
              ) : (
                <h2 className={styles.title}>{title}</h2>
              )}
            </div>
            {!main && !isDesktop && Media}
            {header && <p className={styles.description}>{header}</p>}
            <div
              className={
                hasDate ? `${styles.bottomSection}` : `${styles.noDate}`
              }
            >
              {author && (
                <address className={styles.author}>Par {author}</address>
              )}
              {hasDate && (
                <p className={styles.date}>
                  {publishedAt ? publishedAt.toLocaleDateString("FR") : ""}
                </p>
              )}
            </div>
          </div>
        </div>
      </article>
    </Link>
  );
};

export default HomeArticle;
