import Link from "next/link";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { MiniArticle } from "~/src/types/pages/home.types";
import { ARTICLE_PAGE } from "~/src/utils/constants/page";

import styles from "./index.module.scss";

type Props = MiniArticle;

const TinyArticle = ({ title, header, media, slug }: Props) => {
  return (
    <Link href={`${ARTICLE_PAGE}/${slug}`}>
      <article className={styles.desktopMiniArticle}>
        <LazyLoadImage src={media?.path} className={styles.image} />
        <div>
          <h3 className={styles.title}>
            {title.length > 30 ? `${title.slice(0, 28)}...` : title}
          </h3>
          <p className={styles.description}>
            {!!header && header.length > 80
              ? `${header.slice(0, 80)}...`
              : header}
          </p>
        </div>
      </article>
    </Link>
  );
};

export default TinyArticle;
