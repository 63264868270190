import Link from "next/link";
import { LazyLoadImage } from "react-lazy-load-image-component";

import { useBreakpoint } from "~/src/contexts/providers/breakpoint";
import { MainArticle } from "~/src/types/pages/home.types";
import { ARTICLE_PAGE } from "~/src/utils/constants/page";

import SubscribersPostLabelIcon from "../../icons/subscribers-post-label.icon";
import styles from "./index.module.scss";

const HomeMainArticle = ({
  slug,
  author,
  header,
  title,
  media,
}: MainArticle) => {
  const breakpoint = useBreakpoint();
  const isDesktop = breakpoint === "XL" || breakpoint === "XXL";
  return (
    <Link href={`${ARTICLE_PAGE}/${slug}`}>
      <article className={styles.mainArticle}>
        {isDesktop ? (
          <LazyLoadImage
            src={media?.path}
            className={styles.image}
            alt={media?.caption ?? ""}
          />
        ) : (
          <div>
            <div className={styles.shadowMobileContainer}></div>
            <LazyLoadImage
              src={media?.path}
              className={styles.image}
              alt={media?.caption ?? ""}
            />
          </div>
        )}
        <div className={styles.label}>
          <SubscribersPostLabelIcon />
        </div>
        <div className={isDesktop ? `${styles.detailsContainer}` : ""}>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.description}>{header}</p>
          <p className={styles.author}>Par {author}</p>
        </div>
      </article>
    </Link>
  );
};

export default HomeMainArticle;
