import { LazyLoadImage } from "react-lazy-load-image-component";

import { useBreakpoint } from "~/src/contexts/providers/breakpoint";
import {
  MainCategoryArticle,
  VideoArticle,
} from "~/src/types/pages/home.types";

import HomeArticle from "../../category/Article";
import styles from "./index.module.scss";

type Props = {
  article: MainCategoryArticle;
  videos: VideoArticle[];
};
const FeaturedSectionA = ({ article, videos }: Props) => {
  const breakpoint = useBreakpoint();

  const isDesktop = breakpoint === "XL" || breakpoint === "XXL";
  return (
    <section className={styles.article}>
      <div className={styles.featuredArticleA}>
        <HomeArticle
          {...article}
          header={undefined}
          main={false}
          desktopVideo={true}
          authorUnderTitle={true}
        />
      </div>
      <div className={styles.divider}></div>
      <div className={styles.videos}>
        {/* <FeaturedVideos isLeft articles={videos} /> */}
        {isDesktop && (
          <LazyLoadImage
            className={styles.img}
            src="/images/omerta-offer.png"
            alt="reduction"
          />
        )}
      </div>
    </section>
  );
};

export default FeaturedSectionA;
