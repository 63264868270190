import { MiniArticles } from "~/src/types/pages/home.types";

import styles from "./index.module.scss";
import MiniArticle from "./MiniArticle";

type Props = {
  data: MiniArticles;
};

const MiniArticlesSection = ({ data }: Props) => {
  return (
    <section className={`${styles.miniArticlesSection} container`}>
      {data.map((article, i) => (
        <MiniArticle {...article} key={`desktop-mini-article-${i}`} />
      ))}
    </section>
  );
};

export default MiniArticlesSection;
